<template>
	<div class="u-page">
		<MoleculesAdSlot v-if="showAds.getShowAds()" ad_id="sky-left" ad_unit_small="" ad_unit_large="d_sky_left" />

		<div class="u-content-area">
			<HeaderWrapper />

			<MoleculesAdSlot v-if="showAds.getShowAds()" ad_id="superbanner" ad_unit_small="" ad_unit_large="d_billboard" />

			<main class="u-main-content content-col" :class="mainContentClass" :data-pagetype="systemdata?.contentType">
				<slot />

				<ArticleNewsletter :ressorts="ressorts" :audience="audience"></ArticleNewsletter>

				<OutBrain adId="recommendation" type="recommendation" />

				<MoleculesAdSlot v-if="showAds.getShowAds()" ad_id="billboard2" ad_unit_large="d_billboard2" />
			</main>

			<BreadcrumbWrapper />

			<FooterWrapper></FooterWrapper>
		</div>

		<MoleculesAdSlot v-if="showAds.getShowAds()" ad_id="sky-right" ad_unit_small="" ad_unit_large="d_sky_right" />
	</div>
</template>

<script lang="ts" setup>
	import useMetadata from "~/composables/useMetadata";
	import { useShowAds } from "~/composables/useShowAds";

	const showAds = await useShowAds();

	const props = withDefaults(
		defineProps<{
			systemdata: any;
			content: any;
			metadata?: any;
			mainContentClass?: string;
			pagination?: any;
		}>(),
		{
			systemdata: {
				contentType: "article",
			},
			metadata: {
				payCategory: "plus",
			},
		},
	);

	const { audience, ressorts } = useMetadata(props.metadata);
	// TODO: Replace Breadcrumb, get pagination object
</script>
